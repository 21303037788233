import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <section class="footer">
      <div class="share">
        <a href="#" class="fab fa-facebook-f"></a>
        <a href="#" class="fab fa-twitter"></a>
        <a href="#" class="fab fa-instagram"></a>
        <a href="#" class="fab fa-linkedin"></a>
        <a href="#" class="fab fa-pinterest"></a>
      </div>

      <div class="credit">
        {" "}
        &copy; copyright {year} Q-Tech Dev - Show4. Design by
        <a href="http://www.Q-tech.dev" rel="nofollow">
          <span>Qualified Technologies Development</span>{" "}
        </a>
      </div>
    </section>
  );
};

export default Footer;
