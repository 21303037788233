import React from "react";

import P1 from "./../image/pic-1.png"
import P2 from "./../image/pic-2.png"
import P3 from "./../image/pic-3.png"
import P4 from "./../image/pic-4.png"
import P5 from "./../image/pic-5.png"
import P6 from "./../image/pic-6.png"

const Review = () => {
  return (
    <section class="review" id="review">
      <h1 class="heading">
        {" "}
        <span>studenten bewertung</span>{" "}
      </h1>

      <div class="box-container">
        <div class="box">
          <div class="image">
            <i class="fas fa-quote-left"></i>
            <img src={P1} alt="" />
            <i class="fas fa-quote-right"></i>
          </div>
          <h3>john deo</h3>
          <span>glücklicher student/in</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Dignissimos, enim?
          </p>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star-half-alt"></i>
          </div>
        </div>

        <div class="box">
          <div class="image">
            <i class="fas fa-quote-left"></i>
            <img src={P2} alt="" />
            <i class="fas fa-quote-right"></i>
          </div>
          <h3>john deo</h3>
          <span>glücklicher student/in</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Dignissimos, enim?
          </p>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
        </div>

        <div class="box">
          <div class="image">
            <i class="fas fa-quote-left"></i>
            <img src={P3} alt="" />
            <i class="fas fa-quote-right"></i>
          </div>
          <h3>john deo</h3>
          <span>glücklicher student/in</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Dignissimos, enim?
          </p>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="far fa-star"></i>
          </div>
        </div>

        <div class="box">
          <div class="image">
            <i class="fas fa-quote-left"></i>
            <img src={P4} alt="" />
            <i class="fas fa-quote-right"></i>
          </div>
          <h3>john deo</h3>
          <span>glücklicher student/in</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Dignissimos, enim?
          </p>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="far fa-star"></i>
            <i class="fas fa-star-half-alt"></i>
          </div>
        </div>

        <div class="box">
          <div class="image">
            <i class="fas fa-quote-left"></i>
            <img src={P5} alt="" />
            <i class="fas fa-quote-right"></i>
          </div>
          <h3>john deo</h3>
          <span>glücklicher student/in</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Dignissimos, enim?
          </p>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star-half-alt"></i>
            <i class="far fa-star"></i>
          </div>
        </div>

        <div class="box">
          <div class="image">
            <i class="fas fa-quote-left"></i>
            <img src={P6} alt="" />
            <i class="fas fa-quote-right"></i>
          </div>
          <h3>john deo</h3>
          <span>glücklicher student/in</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Dignissimos, enim?
          </p>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="far fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Review;
