import React from 'react'

const Contact = () => {
  return (
    <section class="contact" id="contact">

    <h1 class="heading"> <span>Kontaktiere uns</span> </h1>

    <form action="">

        <input type="text" placeholder="name" class="box" />
        <input type="email" placeholder="email" class="box" />
        <input type="number" placeholder="nummerr" class="box" />

        <textarea name="" class="box" placeholder="ihre nachricht..." id="" cols="30" rows="10"></textarea>

        <input type="submit" value="senden" class="btn" />

    </form>

</section>
  )
}

export default Contact