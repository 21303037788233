import React from "react";

const Plan = () => {
  return (
    <section class="plan" id="plan">
      <h1 class="heading">
        {" "}
        <span>unsere preise</span>{" "}
      </h1>

      <div class="box-container">
        <div class="box">
          <i class="fas fa-paper-plane"></i>
          <h3>basic plan</h3>
          <ul>
            <li>ganzer kurs</li>
            <li>projekte</li>
            <li>zertifizierung</li>
            <li>job garantie</li>
            <li>24/7 support</li>
          </ul>
          <a href="#"> buchen</a>
          <div class="price">
            {" "}
            <span>€</span>30<span>/mtl</span>{" "}
          </div>
        </div>

        <div class="box">
          <i class="fas fa-plane"></i>
          <h3>standard plan</h3>
          <ul>
            <li>ganzer kurs</li>
            <li>projekte</li>
            <li>zertifizierung</li>
            <li>job garantie</li>
            <li>24/7 support</li>
          </ul>
          <a href="#"> buchen</a>
          <div class="price">
            {" "}
            <span>€</span>60<span>/mtl</span>{" "}
          </div>
        </div>

        <div class="box">
          <i class="fas fa-rocket"></i>
          <h3>premium plan</h3>
          <ul>
            <li>ganzer kurs</li>
            <li>projekte</li>
            <li>zertifizierung</li>
            <li>job garantie</li>
            <li>24/7 support</li>
          </ul>
          <a href="#"> buchen</a>
          <div class="price">
            {" "}
            <span>€</span>90<span>/mtl</span>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plan;
