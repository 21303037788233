import React from 'react'
import T1 from './../image/teacher-1.png'
import T2 from './../image/teacher-2.png'
import T3 from './../image/teacher-3.png'
import T4 from './../image/teacher-4.png'
import T5 from './../image/teacher-5.png'
import T6 from './../image/teacher-6.png'

const Teachers = () => {
  return (
    <section class="teachers" id="teachers">

    <h1 class="heading"> <span>unsere dozenten</span> </h1>

    <div class="box-container">

        <div class="box">
            <img src={T1} alt="" />
            <h3>john deo</h3>
            <span>online dozent</span>
            <div class="share">
                <a href="#" class="fab fa-facebook-f"></a>
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-instagram"></a>
                <a href="#" class="fab fa-linkedin"></a>
            </div>
        </div>

        <div class="box">
            <img src={T2} alt="" />
            <h3>john deo</h3>
            <span>online dozent</span>
            <div class="share">
                <a href="#" class="fab fa-facebook-f"></a>
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-instagram"></a>
                <a href="#" class="fab fa-linkedin"></a>
            </div>
        </div>

        <div class="box">
            <img src={T3} alt="" />
            <h3>john deo</h3>
            <span>online dozent</span>
            <div class="share">
                <a href="#" class="fab fa-facebook-f"></a>
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-instagram"></a>
                <a href="#" class="fab fa-linkedin"></a>
            </div>
        </div>

        <div class="box">
            <img src={T4} alt="" />
            <h3>john deo</h3>
            <span>online dozent</span>
            <div class="share">
                <a href="#" class="fab fa-facebook-f"></a>
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-instagram"></a>
                <a href="#" class="fab fa-linkedin"></a>
            </div>
        </div>

        <div class="box">
            <img src={T5} alt="" />
            <h3>john deo</h3>
            <span>online dozent</span>
            <div class="share">
                <a href="#" class="fab fa-facebook-f"></a>
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-instagram"></a>
                <a href="#" class="fab fa-linkedin"></a>
            </div>
        </div>

        <div class="box">
            <img src={T6} alt="" />
            <h3>john deo</h3>
            <span>online dozent</span>
            <div class="share">
                <a href="#" class="fab fa-facebook-f"></a>
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-instagram"></a>
                <a href="#" class="fab fa-linkedin"></a>
            </div>
        </div>

    </div>

</section>
  )
}

export default Teachers