import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Course from './components/Course';
import Teachers from './components/Teachers';
import Plan from './components/Plan';
import Review from './components/Review';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  useEffect(() => {
    const createBox = () => {
      const span = document.createElement('span');
      span.classList.add('animated-box');

      const size = Math.random() * 80;
      span.style.height = 40 + size + 'px';
      span.style.width = 40 + size + 'px';
      span.style.top = Math.random() * window.innerHeight + 'px';
      span.style.left = Math.random() * window.innerWidth + 'px';

      document.querySelector('.gradient-background').appendChild(span);

      setTimeout(() => {
        span.remove();
      }, 3000);
    };

    const interval = setInterval(createBox, 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="gradient-background"></div>
      <Header />
      <Home />
      <Course />
      <Teachers />
      <Plan />
      <Review />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
