import React from "react";

import C1 from "./../image/course-1.svg"
import C2 from "./../image/course-2.svg"
import C3 from "./../image/course-3.svg"
import C4 from "./../image/course-4.svg"
import C5 from "./../image/course-5.svg"
import C6 from "./../image/course-6.svg"

const Course = () => {
  return (
    <section class="courses" id="courses">
      <h1 class="heading">
        {" "}
        <span>unsere kurse</span>{" "}
      </h1>

      <div class="box-container">
        <div class="box">
          <img src={C1} alt="" />
          <h3>web desing</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
            ex?
          </p>
          <a href="#" class="btn">
            Ganzer kurs
          </a>
          <div class="icons">
            <a href="#">
              {" "}
              <i class="fas fa-book"></i> 5 module{" "}
            </a>
            <a href="#">
              {" "}
              <i class="fas fa-clock"></i> 6 stunden{" "}
            </a>
          </div>
        </div>

        <div class="box">
          <img src={C2} alt="" />
          <h3>seo marketing</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
            ex?
          </p>
          <a href="#" class="btn">
            Ganzer kurs
          </a>
          <div class="icons">
            <a href="#">
              {" "}
              <i class="fas fa-book"></i> 5 module{" "}
            </a>
            <a href="#">
              {" "}
              <i class="fas fa-clock"></i> 6 stunden{" "}
            </a>
          </div>
        </div>

        <div class="box">
          <img src={C3} alt="" />
          <h3>science und Technologie</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
            ex?
          </p>
          <a href="#" class="btn">
            Ganzer kurs
          </a>
          <div class="icons">
            <a href="#">
              {" "}
              <i class="fas fa-book"></i> 5 module{" "}
            </a>
            <a href="#">
              {" "}
              <i class="fas fa-clock"></i> 6 stunden{" "}
            </a>
          </div>
        </div>

        <div class="box">
          <img src={C4} alt="" />
          <h3>musik und tanzen</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
            ex?
          </p>
          <a href="#" class="btn">
            Ganzer kurs
          </a>
          <div class="icons">
            <a href="#">
              {" "}
              <i class="fas fa-book"></i> 5 module{" "}
            </a>
            <a href="#">
              {" "}
              <i class="fas fa-clock"></i> 6 stunden{" "}
            </a>
          </div>
        </div>

        <div class="box">
          <img src={C5} alt="" />
          <h3>Lehre und Personal</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
            ex?
          </p>
          <a href="#" class="btn">
            Ganzer kurs
          </a>
          <div class="icons">
            <a href="#">
              {" "}
              <i class="fas fa-book"></i> 5 module{" "}
            </a>
            <a href="#">
              {" "}
              <i class="fas fa-clock"></i> 6 stunden{" "}
            </a>
          </div>
        </div>

        <div class="box">
          <img src={C6} alt="" />
          <h3>grafik desing</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
            ex?
          </p>
          <a href="#" class="btn">
            Ganzer kurs
          </a>
          <div class="icons">
            <a href="#">
              {" "}
              <i class="fas fa-book"></i> 5 module{" "}
            </a>
            <a href="#">
              {" "}
              <i class="fas fa-clock"></i> 6 stunden{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Course;
