import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <a href="#" className="logo">
        <i className="fas fa-graduation-cap"></i> Show4
      </a>

      <nav className={`navbar ${isMenuOpen ? 'active' : ''}`}>
        <a href="#home">home</a>
        <a href="#courses">Kurse</a>
        <a href="#teachers">Dozenten</a>
        <a href="#plan">Preise</a>
        <a href="#review">Bewertungen</a>
        <a href="#contact">Kontakt</a>
      </nav>

      <div id="menu-btn" className={`fas fa-bars ${isMenuOpen ? 'fa-times' : ''}`} onClick={toggleMenu}></div>
    </header>
  );
};

export default Header;
