import React from 'react'

const Home = () => {
  return (
    <section class="home" id="home">

    <div class="content">
        <h3>Entdecken Sie eine neue Art des Lernens</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi dolores ad tenetur possimus, natus adipisci!</p>
        <a href="#courses" class="btn">erkundingen</a>
        <a href="https://q-tech.dev/beispiele" class="btn">Q-Tech Dev</a>
    </div>

</section>
  )
}

export default Home